var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col gap-y-4" },
    [
      _c("title-row", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "flex flex-row items-center justify-center" },
          [
            _c("h1", { staticClass: "font-display text-2xl font-semibold" }, [
              _vm._v("Test queueing"),
            ]),
            _vm.loading ? _c("base-spinner") : _vm._e(),
            _vm.error
              ? _c(
                  "p",
                  {
                    staticClass:
                      "mx-2 inline-flex items-center font-semibold text-red-800",
                  },
                  [
                    _c("fv-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "warning" },
                    }),
                    _vm._v(" " + _vm._s(_vm.error) + " "),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-col gap-y-1" },
        [
          _c("base-label", [_vm._v("Number of users to add to queue")]),
          _c(
            "div",
            { staticClass: "flex flex-row flex-wrap gap-x-2 gap-y-2" },
            [
              _c("base-input", {
                staticClass: "flex min-w-60 flex-1",
                attrs: {
                  id: "usersToAdd",
                  type: "number",
                  "data-testid": "users-to-add",
                  min: "0",
                },
                model: {
                  value: _vm.usersToAdd,
                  callback: function ($$v) {
                    _vm.usersToAdd = $$v
                  },
                  expression: "usersToAdd",
                },
              }),
              _c(
                "base-button",
                {
                  staticClass: "flex min-w-60 flex-1",
                  attrs: { loading: _vm.loading },
                  on: { click: _vm.addUsersToQueue },
                },
                [_vm._v("Add")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex flex-col gap-y-1" },
        [
          _c("base-label", [_vm._v("Previous runs")]),
          _vm._l(_vm.runs, function (run, index) {
            return _c("li", { key: index, staticClass: "list-none" }, [
              _c("p", [_vm._v(_vm._s(run))]),
            ])
          }),
          _vm.hasNoPrevRuns
            ? _c("label", [_vm._v("No previous runs")])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }