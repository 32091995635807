var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("title-row", { staticClass: "w-full" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-between" },
          [
            _c("h1", { staticClass: "font-display text-2xl font-semibold" }, [
              _vm._v("Languages"),
            ]),
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [
                _vm.uiState === "loading" ? _c("base-spinner") : _vm._e(),
                _vm.error
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "mb-2 inline-flex items-center font-semibold text-red-800",
                      },
                      [
                        _c("fv-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "warning" },
                        }),
                        _vm._v(" " + _vm._s(_vm.error) + " "),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.uiState === "success"
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "mb-2 inline-flex items-center font-semibold text-fv-green",
                      },
                      [
                        _c("fv-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "check-mark-alt" },
                        }),
                        _vm._v(" Information updated "),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      !_vm.isAdmin
        ? _c("AccountAdminText", {
            staticClass: "my-2",
            attrs: { title: "Languages" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "py-4" },
        [
          _c("base-label", { attrs: { for: "selectedLanguages" } }, [
            _vm._v(" Add a language "),
          ]),
          _c("div", { staticClass: "mt-2 flex justify-between space-x-12" }, [
            _c(
              "div",
              { staticClass: "w-1/2" },
              [
                _c("v-select", {
                  attrs: {
                    id: "selectedLanguages",
                    options: _vm.availableLanguages,
                    label: "name",
                    clearable: false,
                    selectable: function (opt) {
                      return !_vm.langIds.includes(opt.id)
                    },
                    disabled: _vm.uiState === "loadingLangages" || !_vm.isAdmin,
                    "data-testid": "add-language",
                  },
                  on: { input: _vm.updateLanguages },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "w-1/2" }, [
              _c(
                "ul",
                { staticClass: "flex flex-col space-y-2" },
                _vm._l(_vm.setLanguages, function (lang) {
                  return _c(
                    "li",
                    {
                      key: lang.id,
                      staticClass: "rounded border border-gray-200 p-2",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex w-full justify-between" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "inline-flex items-center space-x-2",
                            },
                            [
                              _c("span", [
                                _c("img", {
                                  staticClass: "h-6 w-6 rounded-full",
                                  attrs: { src: lang.flag_image },
                                }),
                              ]),
                              _c("span", {}, [_vm._v(_vm._s(lang.name))]),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              class: {
                                "cursor-default text-gray-400": !_vm.isAdmin,
                              },
                              attrs: { disabled: !_vm.isAdmin },
                              on: {
                                click: function ($event) {
                                  return _vm.removeLanguage(lang.id)
                                },
                              },
                            },
                            [_c("fv-icon", { attrs: { icon: "delete" } })],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }