<template>
  <div>
    <title-row class="w-full">
      <div class="flex items-center justify-between">
        <h1 class="font-display text-2xl font-semibold">Animal types</h1>
        <transition name="fade">
          <base-spinner v-if="uiState === 'loading'" />
          <p
            v-if="error"
            class="mb-2 inline-flex items-center font-semibold text-red-800"
          >
            <fv-icon icon="warning" class="mr-2" />
            {{ error }}
          </p>
          <p
            v-if="uiState === 'success'"
            class="mb-2 inline-flex items-center font-semibold text-fv-green"
          >
            <fv-icon icon="check-mark-alt" class="mr-2" />
            Information updated
          </p>
        </transition>
      </div>
    </title-row>
    <AccountAdminText v-if="!isAdmin" class="my-2" title="Animal types" />
    <div class="py-4">
      <ul class="grid w-3/4 grid-cols-4 gap-y-6">
        <li
          v-for="animalType in animalTypes"
          :key="animalType.id"
          class="col-span-1"
        >
          <label :for="animalType.id" class="inline-flex items-center">
            <input
              :id="animalType.id"
              v-model="animalTypeList"
              type="checkbox"
              name="animalTypeList"
              :value="animalType.id"
              :disabled="!isAdmin"
              data-testid="animal-type-checkbox"
              @change="update"
            />
            <span class="ml-2">{{ animalType.name }}</span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { debounce } from 'lodash';
import AccountAdminText from '@/components/account/AccountAdminText';
import { UserApi } from '@/api';

export default {
  components: {
    AccountAdminText,
  },
  props: {
    setAnimalTypes: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: Number,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      deafult: false,
    },
  },

  data() {
    return {
      animalTypeList: [],
      uiState: 'idle',
      error: '',
    };
  },

  computed: {
    ...mapState('animal', ['animalTypes']),
  },

  mounted() {
    if (!this.animalTypes.length) {
      this.fetchAnimalTypes();
    }
    this.animalTypeList = this.setAnimalTypes.map(type => type.id);
  },

  methods: {
    ...mapActions('animal', ['fetchAnimalTypes']),
    async updateAnimalTypes() {
      this.uiState = 'loading';
      this.error = '';
      try {
        const { data } = await UserApi.updateAnimalTypes(
          this.animalTypeList,
          this.userId
        );
        this.uiState = 'success';
        this.$emit('updated', data.animal_types);

        setTimeout(() => {
          this.uiState = 'idle';
        }, 3000);
      } catch (error) {
        this.uiState = 'error';
        this.error = error.message;
      }
    },
    // eslint-disable-next-line func-names
    update: debounce(async function () {
      await this.updateAnimalTypes();
    }, 1000),
  },
};
</script>
