var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "rounded-br-md rounded-tr-md font-semibold hover:text-gray-900",
      class: _vm.selected ? "bg-primary-lightest " : "text-gray-500",
      attrs: { "data-testid": _vm.button.name },
      on: {
        click: function ($event) {
          return _vm.$emit("selected", _vm.button.name)
        },
      },
    },
    [
      _c(
        "span",
        { staticClass: "flex items-center py-3 px-6 leading-none" },
        [
          _c("fv-icon", {
            staticClass: "mr-2",
            attrs: { icon: _vm.button.icon },
          }),
          _vm._v(" " + _vm._s(_vm.button.name) + " "),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }