<template>
  <div
    class="text-sm text-gray-600 flex items-center space-x-1 leading-none"
  >
    <fv-icon
      icon="information"
      class="text-gray-600"
    />
    <p>{{ title }} are set by admins</p>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
