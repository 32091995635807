var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      !_vm.imageLoadError && _vm.avatar
        ? _c("img", {
            staticClass: "relative h-56 w-36 rounded-lg object-cover",
            attrs: { src: _vm.avatar, alt: "Call notification picture" },
            on: {
              error: function ($event) {
                _vm.imageLoadError = true
              },
            },
          })
        : _c(
            "font-awesome-layers",
            { staticClass: "fa-lg relative h-56 w-36" },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "circle", color: "#b8c2cc" },
              }),
              _c("font-awesome-icon", {
                attrs: {
                  icon: "user-md",
                  transform: "shrink-6",
                  color: "#ffffff",
                },
              }),
            ],
            1
          ),
      _c("img", {
        staticClass: "absolute top-0 left-0 h-56 w-36 rounded-lg",
        attrs: {
          src: require("@/assets/svg/android_call_notificaiton_overlay.svg"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }