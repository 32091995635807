var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("title-row", { staticClass: "w-full" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-between" },
          [
            _c("h1", { staticClass: "font-display text-2xl font-semibold" }, [
              _vm._v("General information"),
            ]),
            _vm.uiState === "loading" ? _c("base-spinner") : _vm._e(),
            _vm.error
              ? _c(
                  "p",
                  {
                    staticClass:
                      "mb-2 inline-flex items-center font-semibold text-red-800",
                  },
                  [
                    _c("fv-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "warning" },
                    }),
                    _vm._v(" " + _vm._s(_vm.error) + " "),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-col-reverse pt-4 xl:flex-row" },
        [
          _c(
            "ValidationObserver",
            {
              ref: "observer",
              staticClass: "space-y-3",
              attrs: { tag: "form" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("ValidationProvider", {
                attrs: { name: "display name", tag: "div" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var errors = ref.errors
                      return [
                        _c("base-label", { attrs: { for: "display name" } }, [
                          _vm._v(" Display name "),
                        ]),
                        _c("base-input", {
                          staticClass: "mt-1",
                          attrs: {
                            id: "display name",
                            name: "display name",
                            placeholder: "Display name",
                            "data-testid": "display-name",
                          },
                          model: {
                            value: _vm.edit.display_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "display_name", $$v)
                            },
                            expression: "edit.display_name",
                          },
                        }),
                        _c("transition", { attrs: { name: "fade" } }, [
                          errors.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-1 text-sm font-semibold text-red-800",
                                },
                                [_vm._v(" " + _vm._s(errors[0]) + " ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("AccountJobTitle", {
                attrs: { "is-admin": _vm.isAdmin, value: _vm.user.jobTitle },
                on: {
                  change: function ($event) {
                    _vm.jobTitle = $event
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "flex space-x-4" },
                [
                  _c("ValidationProvider", {
                    staticClass: "flex-1",
                    attrs: { name: "first name", tag: "div" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("base-label", { attrs: { for: "first name" } }, [
                              _vm._v(" First name "),
                            ]),
                            _c("base-input", {
                              staticClass: "mt-1",
                              attrs: {
                                id: "first name",
                                name: "first name",
                                placeholder: "First name",
                                "data-testid": "first-name-input",
                              },
                              model: {
                                value: _vm.edit.first_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "first_name", $$v)
                                },
                                expression: "edit.first_name",
                              },
                            }),
                            _c("transition", { attrs: { name: "fade" } }, [
                              errors.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-1 text-sm font-semibold text-red-800",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("ValidationProvider", {
                    staticClass: "flex-1",
                    attrs: { name: "last name", tag: "div" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("base-label", { attrs: { for: "last name" } }, [
                              _vm._v(" Last name "),
                            ]),
                            _c("base-input", {
                              staticClass: "mt-1",
                              attrs: {
                                id: "last name",
                                name: "last name",
                                placeholder: "last name",
                                "data-testid": "last-name-input",
                              },
                              model: {
                                value: _vm.edit.last_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit, "last_name", $$v)
                                },
                                expression: "edit.last_name",
                              },
                            }),
                            _c("transition", { attrs: { name: "fade" } }, [
                              errors.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-1 text-sm font-semibold text-red-800",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("div", [
                _c("div", { staticClass: "flex space-x-4" }, [
                  _c(
                    "div",
                    { staticClass: "w-96 space-y-1" },
                    [
                      _c("base-label", { attrs: { for: "countryCode" } }, [
                        _vm._v(" Country code "),
                      ]),
                      _c("v-select", {
                        attrs: {
                          options: _vm.filteredCountryCodes,
                          label: "label",
                          loading:
                            _vm.uiState === "loading" || !_vm.countryCodes,
                          clearable: false,
                        },
                        on: {
                          input: function ($event) {
                            _vm.error = ""
                          },
                        },
                        model: {
                          value: _vm.countryCode,
                          callback: function ($$v) {
                            _vm.countryCode = $$v
                          },
                          expression: "countryCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-full space-y-1" },
                    [
                      _c("base-label", { attrs: { for: "phoneNumber" } }, [
                        _vm._v(" Phone number "),
                      ]),
                      _c("base-input", {
                        attrs: {
                          id: "phoneNumber",
                          "data-testid": "phone-number-input",
                        },
                        model: {
                          value: _vm.edit.mobile_phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "mobile_phone", $$v)
                          },
                          expression: "edit.mobile_phone",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                !_vm.phoneNumberIsValid && _vm.uiState !== "loading"
                  ? _c(
                      "p",
                      {
                        staticClass: "mt-1 text-sm font-semibold text-red-800",
                      },
                      [_vm._v(" " + _vm._s(_vm.phoneError) + " ")]
                    )
                  : _vm._e(),
              ]),
              _c("ValidationProvider", {
                attrs: {
                  rules: { required: true, email: true },
                  name: "email",
                  tag: "div",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var errors = ref.errors
                      return [
                        _c("base-label", { attrs: { for: "email" } }, [
                          _vm._v(" Email address "),
                        ]),
                        _c("base-input", {
                          staticClass: "mt-1",
                          attrs: {
                            id: "email",
                            name: "email",
                            placeholder: "Email",
                          },
                          model: {
                            value: _vm.edit.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.edit, "email", $$v)
                            },
                            expression: "edit.email",
                          },
                        }),
                        _c("transition", { attrs: { name: "fade" } }, [
                          errors.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-1 text-sm font-semibold text-red-800",
                                },
                                [_vm._v(" " + _vm._s(errors[0]) + " ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isUS
                ? _c(
                    "div",
                    [
                      _c("base-label", { attrs: { for: "state" } }, [
                        _vm._v(" State "),
                      ]),
                      _c("v-select", {
                        attrs: {
                          id: "state",
                          options: _vm.usStates,
                          label: "state",
                          placeholder: "Select a state",
                          loading: _vm.uiState === "loading" || !_vm.usStates,
                          clearable: false,
                        },
                        model: {
                          value: _vm.state,
                          callback: function ($$v) {
                            _vm.state = $$v
                          },
                          expression: "state",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c("base-label", { attrs: { for: "country" } }, [
                    _vm._v(" Country "),
                  ]),
                  _c("v-select", {
                    attrs: {
                      id: "country",
                      options: _vm.countries,
                      label: "name",
                      loading: _vm.uiState === "loading" || !_vm.countries,
                      clearable: false,
                      disabled: !_vm.isAdmin,
                      "data-testid": "country",
                    },
                    model: {
                      value: _vm.country,
                      callback: function ($$v) {
                        _vm.country = $$v
                      },
                      expression: "country",
                    },
                  }),
                  !_vm.isAdmin
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mt-1 flex items-center space-x-1 text-sm leading-none text-gray-600",
                        },
                        [
                          _c("fv-icon", {
                            staticClass: "text-gray-600",
                            attrs: { icon: "information" },
                          }),
                          _c("p", [_vm._v("Country is set by Admin")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.platform === "sv"
                ? _c(
                    "div",
                    [
                      _c("base-label", { attrs: { for: "apoexApiKey" } }, [
                        _vm._v(" ApoEx API Key "),
                      ]),
                      _c("base-input", {
                        attrs: { id: "apoexApiKey", type: "password" },
                        model: {
                          value: _vm.edit.apoex_api_key,
                          callback: function ($$v) {
                            _vm.$set(_vm.edit, "apoex_api_key", $$v)
                          },
                          expression: "edit.apoex_api_key",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex justify-end pt-2" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "w-1/3",
                      attrs: {
                        color: "primary",
                        loading: _vm.loading,
                        "data-testid": "save-button",
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "justify-evenly px-2 pt-2 md:flex md:flex-row xl:flex-1 xl:flex-col",
            },
            [
              _c("AccountAvatar", {
                attrs: { user: _vm.user },
                on: {
                  removed: function ($event) {
                    ;(_vm.user.avatarUrl = null), (_vm.user.avatar = null)
                  },
                },
              }),
              _c("account-call-notification-avatar", {
                attrs: { user: _vm.user },
                on: {
                  removed: function ($event) {
                    ;(_vm.user.callNotificationAvatarUrl = null),
                      (_vm.user.callNotificationAvatarUrl = null)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }