var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("title-row", { staticClass: "w-full" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-between" },
          [
            _c("h1", { staticClass: "font-display text-2xl font-semibold" }, [
              _vm._v("Notifications"),
            ]),
            _c("spinner-overlay", {
              attrs: {
                loading: !_vm.availableNotifications.length,
                color: "light",
                size: "xl",
              },
            }),
            _vm.uiState === "loading" ? _c("base-spinner") : _vm._e(),
            _vm.error
              ? _c(
                  "p",
                  {
                    staticClass:
                      "mb-2 inline-flex items-center font-semibold text-red-800",
                  },
                  [
                    _c("fv-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "warning" },
                    }),
                    _vm._v(" " + _vm._s(_vm.error) + " "),
                  ],
                  1
                )
              : _vm._e(),
            _vm.uiState === "success"
              ? _c(
                  "p",
                  {
                    staticClass:
                      "mb-2 inline-flex items-center font-semibold text-fv-green",
                    attrs: { "data-testid": "information-saved" },
                  },
                  [
                    _c("fv-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "check-mark-alt" },
                    }),
                    _vm._v(" Information updated "),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._l(_vm.availableNotifications, function (notification) {
        return _c(
          "div",
          {
            key: notification.id,
            staticClass: "flex items-center justify-between border-b py-4",
          },
          [
            _c("div", [
              _c(
                "label",
                {
                  staticClass: "mb-1 block font-semibold",
                  attrs: { for: notification.id },
                },
                [
                  _vm._v(
                    " " + _vm._s(notification.label || notification.name) + " "
                  ),
                ]
              ),
              _c("span", { staticClass: "text-sm text-gray-600" }, [
                _vm._v(_vm._s(notification.description)),
              ]),
            ]),
            _c("div", { staticClass: "form-switch max-h-full" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.notificationList,
                    expression: "notificationList",
                  },
                ],
                staticClass: "form-switch-checkbox",
                attrs: { id: notification.id, type: "checkbox" },
                domProps: {
                  checked: _vm.notificationList.includes(notification.id),
                  value: notification.id,
                  checked: Array.isArray(_vm.notificationList)
                    ? _vm._i(_vm.notificationList, notification.id) > -1
                    : _vm.notificationList,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.notificationList,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = notification.id,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.notificationList = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.notificationList = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.notificationList = $$c
                      }
                    },
                    _vm.updateNotifications,
                  ],
                },
              }),
              _c("label", {
                staticClass: "form-switch-label display-none",
                attrs: {
                  for: notification.id,
                  "data-testid": "notification-switch",
                },
              }),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }