var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("title-row", { staticClass: "w-full" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-between" },
          [
            _c("h1", { staticClass: "font-display text-2xl font-semibold" }, [
              _vm._v("Work hours"),
            ]),
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [
                _vm.uiState === "loading" ? _c("base-spinner") : _vm._e(),
                _vm.error
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "mb-2 inline-flex items-center font-semibold text-red-800",
                      },
                      [
                        _c("fv-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "warning" },
                        }),
                        _vm._v(" " + _vm._s(_vm.error) + " "),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.uiState === "success"
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "mb-2 inline-flex items-center font-semibold text-fv-green",
                      },
                      [
                        _c("fv-icon", {
                          staticClass: "mr-2",
                          attrs: {
                            icon: "check-mark-alt",
                            "data-testid": "checkmark-icon",
                          },
                        }),
                        _vm._v(" Information updated "),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      !_vm.isAdmin
        ? _c("AccountAdminText", {
            staticClass: "my-2",
            attrs: { title: "Work hours" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex w-full flex-col space-y-4 py-4" },
        [
          _c(
            "ValidationObserver",
            {
              ref: "observer",
              staticClass: "flex w-1/2 flex-col space-y-4",
              attrs: { tag: "form" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("ValidationProvider", {
                staticClass: "flex w-full flex-col space-y-1",
                attrs: {
                  name: "start time",
                  tag: "div",
                  rules: { required: true },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var errors = ref.errors
                      return [
                        _c("base-label", { attrs: { for: "startTime" } }, [
                          _vm._v("Work day start"),
                        ]),
                        _c("v-select", {
                          attrs: {
                            id: "startTime",
                            options: _vm.hourOptions,
                            label: "name",
                            clearable: false,
                            disabled: !_vm.isAdmin,
                            "data-testid": "work-day-start",
                          },
                          model: {
                            value: _vm.start,
                            callback: function ($$v) {
                              _vm.start = $$v
                            },
                            expression: "start",
                          },
                        }),
                        _c("transition", { attrs: { name: "fade" } }, [
                          errors.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-1 text-sm font-semibold text-red-800",
                                },
                                [_vm._v(" " + _vm._s(errors[0]) + " ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("ValidationProvider", {
                staticClass: "flex w-full flex-col space-y-1",
                attrs: {
                  name: "end time",
                  tag: "div",
                  rules: { required: true },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var errors = ref.errors
                      return [
                        _c("base-label", { attrs: { for: "endTime" } }, [
                          _vm._v("Work day end "),
                        ]),
                        _c("v-select", {
                          attrs: {
                            id: "endTime",
                            options: _vm.hourOptions,
                            label: "name",
                            clearable: false,
                            disabled: !_vm.isAdmin,
                            "data-testid": "work-day-end",
                          },
                          model: {
                            value: _vm.end,
                            callback: function ($$v) {
                              _vm.end = $$v
                            },
                            expression: "end",
                          },
                        }),
                        _c("transition", { attrs: { name: "fade" } }, [
                          errors.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-1 text-sm font-semibold text-red-800",
                                },
                                [_vm._v(" " + _vm._s(errors[0]) + " ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isAdmin
                ? _c(
                    "div",
                    { staticClass: "flex justify-end" },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.uiState === "loading",
                            "data-testid": "save-button",
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }