<template>
  <div class="relative">
    <img
      v-if="!imageLoadError && avatar"
      :src="avatar"
      alt="Call notification picture"
      class="relative h-56 w-36 rounded-lg object-cover"
      @error="imageLoadError = true"
    />
    <font-awesome-layers v-else class="fa-lg relative h-56 w-36">
      <font-awesome-icon icon="circle" color="#b8c2cc" />
      <font-awesome-icon icon="user-md" transform="shrink-6" color="#ffffff" />
    </font-awesome-layers>
    <img
      :src="require('@/assets/svg/android_call_notificaiton_overlay.svg')"
      class="absolute top-0 left-0 h-56 w-36 rounded-lg"
    />
  </div>
</template>

<script>
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { mapState } from 'vuex';

export default {
  components: {
    FontAwesomeLayers,
  },
  props: {
    avatar: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imageLoadError: false,
    };
  },
  computed: {
    ...mapState(['platform']),
  },
};
</script>
