<template>
  <button
    class="rounded-br-md rounded-tr-md font-semibold hover:text-gray-900"
    :class="selected ? 'bg-primary-lightest ' : 'text-gray-500'"
    :data-testid="button.name"
    @click="$emit('selected', button.name)"
  >
    <span class="flex items-center py-3 px-6 leading-none">
      <fv-icon :icon="button.icon" class="mr-2" />
      {{ button.name }}
    </span>
  </button>
</template>

<script>
export default {
  props: {
    button: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>
