var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("title-row", { staticClass: "w-full" }, [
        _c(
          "div",
          { staticClass: "flex justify-between items-center" },
          [
            _c("h1", { staticClass: "font-semibold font-display text-2xl" }, [
              _vm._v(" Veterinary licences "),
            ]),
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [
                _vm.uiState === "loading" ? _c("base-spinner") : _vm._e(),
                _vm.error
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "text-red-800 mb-2 font-semibold inline-flex items-center",
                      },
                      [
                        _c("fv-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "warning" },
                        }),
                        _vm._v(" " + _vm._s(_vm.error) + " "),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.uiState === "success"
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "text-fv-green mb-2 font-semibold inline-flex items-center",
                      },
                      [
                        _c("fv-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "check-mark-alt" },
                        }),
                        _vm._v(" Information updated "),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      !_vm.isAdmin
        ? _c("AccountAdminText", {
            staticClass: "my-2",
            attrs: { title: "State licences" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "py-4" },
        [
          _c("base-label", { attrs: { for: "presciptionEnabledStates" } }, [
            _vm._v(" Add a licence for a specific state "),
          ]),
          _c("div", { staticClass: "flex space-x-12 justify-between mt-2" }, [
            _c(
              "div",
              { staticClass: "w-1/2" },
              [
                _c("v-select", {
                  attrs: {
                    id: "presciptionEnabledStates",
                    options: _vm.usStates,
                    label: "state",
                    clearable: false,
                    selectable: function (opt) {
                      return !_vm.stateIds.includes(opt.id)
                    },
                    disabled: !_vm.usStates.length || !_vm.isAdmin,
                    loading: !_vm.usStates.length,
                  },
                  on: { input: _vm.updateStateLicences },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "w-1/2" }, [
              _c(
                "ul",
                { staticClass: "flex flex-col space-y-2" },
                _vm._l(_vm.setStateLicences, function (state) {
                  return _c(
                    "li",
                    {
                      key: state.id,
                      staticClass: "border p-2 rounded border-gray-200",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-full flex justify-between" },
                        [
                          _vm._v(" " + _vm._s(state.state) + " "),
                          _c(
                            "button",
                            {
                              class: {
                                "cursor-default text-gray-400": !_vm.isAdmin,
                              },
                              attrs: { disabled: !_vm.isAdmin },
                              on: {
                                click: function ($event) {
                                  return _vm.removeState(state.id)
                                },
                              },
                            },
                            [_c("fv-icon", { attrs: { icon: "delete" } })],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }