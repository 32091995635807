<template>
  <div class="mr-6 flex flex-col pt-8 bg-gray-50 pr-2 border-r">
    <AccountNavButton
      v-for="button in buttons"
      :key="button.name"
      :button="button"
      :selected="button.name === selected"
      @selected="$emit('selected', button.name)"
    />
  </div>
</template>

<script>
import AccountNavButton from '@/components/account/AccountNavButton';

export default {
  components: {
    AccountNavButton,
  },
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      default: 'General',
    },
  },
  data() {
    return {};
  },
};
</script>
