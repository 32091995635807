<template>
  <div>
    <title-row class="w-full">
      <div class="flex items-center justify-between">
        <h1 class="font-display text-2xl font-semibold">General information</h1>
        <base-spinner v-if="uiState === 'loading'" />
        <p
          v-if="error"
          class="mb-2 inline-flex items-center font-semibold text-red-800"
        >
          <fv-icon icon="warning" class="mr-2" />
          {{ error }}
        </p>
      </div>
    </title-row>

    <div class="flex flex-col-reverse pt-4 xl:flex-row">
      <ValidationObserver
        ref="observer"
        tag="form"
        class="space-y-3"
        @submit.prevent="submit"
      >
        <ValidationProvider v-slot="{ errors }" name="display name" tag="div">
          <base-label for="display name"> Display name </base-label>
          <base-input
            id="display name"
            v-model="edit.display_name"
            name="display name"
            placeholder="Display name"
            class="mt-1"
            data-testid="display-name"
          />
          <transition name="fade">
            <div
              v-if="errors.length"
              class="mt-1 text-sm font-semibold text-red-800"
            >
              {{ errors[0] }}
            </div>
          </transition>
        </ValidationProvider>

        <AccountJobTitle
          :is-admin="isAdmin"
          :value="user.jobTitle"
          @change="jobTitle = $event"
        ></AccountJobTitle>

        <div class="flex space-x-4">
          <ValidationProvider
            v-slot="{ errors }"
            name="first name"
            tag="div"
            class="flex-1"
          >
            <base-label for="first name"> First name </base-label>
            <base-input
              id="first name"
              v-model="edit.first_name"
              name="first name"
              placeholder="First name"
              class="mt-1"
              data-testid="first-name-input"
            />
            <transition name="fade">
              <div
                v-if="errors.length"
                class="mt-1 text-sm font-semibold text-red-800"
              >
                {{ errors[0] }}
              </div>
            </transition>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="last name"
            tag="div"
            class="flex-1"
          >
            <base-label for="last name"> Last name </base-label>
            <base-input
              id="last name"
              v-model="edit.last_name"
              name="last name"
              placeholder="last name"
              class="mt-1"
              data-testid="last-name-input"
            />
            <transition name="fade">
              <div
                v-if="errors.length"
                class="mt-1 text-sm font-semibold text-red-800"
              >
                {{ errors[0] }}
              </div>
            </transition>
          </ValidationProvider>
        </div>

        <div>
          <div class="flex space-x-4">
            <div class="w-96 space-y-1">
              <base-label for="countryCode"> Country code </base-label>
              <v-select
                v-model="countryCode"
                :options="filteredCountryCodes"
                label="label"
                :loading="uiState === 'loading' || !countryCodes"
                :clearable="false"
                @input="error = ''"
              />
            </div>
            <div class="w-full space-y-1">
              <base-label for="phoneNumber"> Phone number </base-label>
              <base-input
                id="phoneNumber"
                v-model="edit.mobile_phone"
                data-testid="phone-number-input"
              />
            </div>
          </div>
          <p
            v-if="!phoneNumberIsValid && uiState !== 'loading'"
            class="mt-1 text-sm font-semibold text-red-800"
          >
            {{ phoneError }}
          </p>
        </div>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="{ required: true, email: true }"
          name="email"
          tag="div"
        >
          <base-label for="email"> Email address </base-label>
          <base-input
            id="email"
            v-model="edit.email"
            name="email"
            placeholder="Email"
            class="mt-1"
          />
          <transition name="fade">
            <div
              v-if="errors.length"
              class="mt-1 text-sm font-semibold text-red-800"
            >
              {{ errors[0] }}
            </div>
          </transition>
        </ValidationProvider>
        <div v-if="isUS">
          <base-label for="state"> State </base-label>
          <v-select
            id="state"
            v-model="state"
            :options="usStates"
            label="state"
            placeholder="Select a state"
            :loading="uiState === 'loading' || !usStates"
            :clearable="false"
          />
        </div>
        <div>
          <base-label for="country"> Country </base-label>
          <v-select
            id="country"
            v-model="country"
            :options="countries"
            label="name"
            :loading="uiState === 'loading' || !countries"
            :clearable="false"
            :disabled="!isAdmin"
            data-testid="country"
          />
          <div
            v-if="!isAdmin"
            class="mt-1 flex items-center space-x-1 text-sm leading-none text-gray-600"
          >
            <fv-icon icon="information" class="text-gray-600" />
            <p>Country is set by Admin</p>
          </div>
        </div>
        <div v-if="platform === 'sv'">
          <base-label for="apoexApiKey"> ApoEx API Key </base-label>
          <base-input
            id="apoexApiKey"
            v-model="edit.apoex_api_key"
            type="password"
          />
        </div>
        <div class="flex justify-end pt-2">
          <base-button
            color="primary"
            :loading="loading"
            class="w-1/3"
            data-testid="save-button"
          >
            Save
          </base-button>
        </div>
      </ValidationObserver>
      <div
        class="justify-evenly px-2 pt-2 md:flex md:flex-row xl:flex-1 xl:flex-col"
      >
        <AccountAvatar
          :user="user"
          @removed="(user.avatarUrl = null), (user.avatar = null)"
        />
        <account-call-notification-avatar
          :user="user"
          @removed="
            (user.callNotificationAvatarUrl = null),
              (user.callNotificationAvatarUrl = null)
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PhoneNumber from 'awesome-phonenumber';
import AccountAvatar from '@/components/account/AccountAvatar';
import { UserApi } from '@/api';
import { US_COUNTRY_ID } from '@/config/countries';
import AccountCallNotificationAvatar from '@/components/account/AccountCallNotificationAvatar';
import AccountJobTitle from '@/components/account/AccountJobTitle';

export default {
  components: {
    AccountCallNotificationAvatar,
    AccountAvatar,
    AccountJobTitle,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      edit: _.cloneDeep(this.user),
      countryCode: null,
      country: null,
      jobTitle: undefined,
      uiState: 'loading',
      error: '',
      state: '',
      phoneError: 'The phone number you have entered is not valid',
    };
  },
  computed: {
    ...mapGetters('user', { loggedInUserid: 'getUserId' }),
    ...mapState('country', [
      'countries',
      'usStates',
      'timezones',
      'countryCodes',
    ]),
    ...mapGetters({
      platform: 'getPlatform',
    }),
    filteredCountryCodes() {
      if (!this.countryCodes.length) return [];
      return this.countryCodes.map(country => ({
        ...country,
        label: `+${country.code} (${country.country})`,
      }));
    },
    phoneNumberIsValid() {
      if (!this.countryCode?.code) return false;
      const phoneNumber = `+${this.countryCode.code}${this.edit.mobile_phone}`;
      return new PhoneNumber(phoneNumber).isValid();
    },
    isUS() {
      return this.user.country_id === US_COUNTRY_ID;
    },
  },

  async mounted() {
    const promises = [];

    if (!this.countryCodes.length) {
      promises.push(this.fetchCountryCodes());
    }

    if (!this.countries.length) {
      promises.push(this.fetchCountries());
    }

    if (!this.usStates.length) {
      promises.push(this.fetchUsStates());
    }

    await Promise.all(promises).finally(() => {
      this.uiState = 'idle';
    });

    if (this.user.country_id) {
      this.country = this.countries.find(cc => cc.id === this.user.country_id);
    }

    if (this.user.us_state_id) {
      this.state = this.usStates.find(cc => cc.id === this.user.us_state_id);
    }

    if (this.user.country_code_id) {
      this.countryCode = this.filteredCountryCodes.find(
        cc => cc.id === this.user.country_code_id
      );
    }
  },
  methods: {
    ...mapActions('user', {
      updateUserState: 'setUser',
    }),
    ...mapActions('country', [
      'fetchTimezones',
      'fetchCountryCodes',
      'fetchCountries',
      'fetchUsStates',
    ]),

    async submit() {
      this.error = '';
      this.loading = true;

      const valid = await this.$refs.observer.validate();

      if (!valid || !this.phoneNumberIsValid) {
        this.loading = false;
        return;
      }

      try {
        this.edit.country_code_id = this.countryCode.id;
        this.edit.country_id = this.country.id;
        this.edit.us_state_id = this.state.id;
        this.edit.job_title_id = this.jobTitle?.id;

        const { data } = await UserApi.updateUserDetails(this.edit);
        const user = { ...data.user, avatarUrl: this.user.avatarUrl };
        this.edit = user;
        this.$emit('updated', user);
        if (user.id == this.loggedInUserid) {
          this.$store.commit('user/setUser', user);
        }
        this.loading = false;

        this.$notify({
          group: 'primary',
          title: 'Success!',
          text: 'User details have been updated',
        });
        this.$emit('close');
      } catch (error) {
        this.$notify({
          group: 'error',
          title: 'Something went wrong',
          text: 'Please try again or submit a support ticket',
        });
        this.loading = false;
      }
    },
  },
};
</script>
