<template>
  <div>
    <title-row class="w-full">
      <div class="flex items-center justify-between">
        <h1 class="font-display text-2xl font-semibold">Work hours</h1>

        <transition name="fade">
          <base-spinner v-if="uiState === 'loading'" />
          <p
            v-if="error"
            class="mb-2 inline-flex items-center font-semibold text-red-800"
          >
            <fv-icon icon="warning" class="mr-2" />
            {{ error }}
          </p>
          <p
            v-if="uiState === 'success'"
            class="mb-2 inline-flex items-center font-semibold text-fv-green"
          >
            <fv-icon
              icon="check-mark-alt"
              class="mr-2"
              data-testid="checkmark-icon"
            />
            Information updated
          </p>
        </transition>
      </div>
    </title-row>
    <AccountAdminText v-if="!isAdmin" class="my-2" title="Work hours" />
    <div class="flex w-full flex-col space-y-4 py-4">
      <ValidationObserver
        ref="observer"
        tag="form"
        class="flex w-1/2 flex-col space-y-4"
        @submit.prevent="submit"
      >
        <ValidationProvider
          v-slot="{ errors }"
          name="start time"
          tag="div"
          class="flex w-full flex-col space-y-1"
          :rules="{ required: true }"
        >
          <base-label for="startTime">Work day start</base-label>
          <v-select
            id="startTime"
            v-model="start"
            :options="hourOptions"
            label="name"
            :clearable="false"
            :disabled="!isAdmin"
            data-testid="work-day-start"
          />
          <transition name="fade">
            <div
              v-if="errors.length"
              class="mt-1 text-sm font-semibold text-red-800"
            >
              {{ errors[0] }}
            </div>
          </transition>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="end time"
          tag="div"
          class="flex w-full flex-col space-y-1"
          :rules="{ required: true }"
        >
          <base-label for="endTime">Work day end </base-label>
          <v-select
            id="endTime"
            v-model="end"
            :options="hourOptions"
            label="name"
            :clearable="false"
            :disabled="!isAdmin"
            data-testid="work-day-end"
          />
          <transition name="fade">
            <div
              v-if="errors.length"
              class="mt-1 text-sm font-semibold text-red-800"
            >
              {{ errors[0] }}
            </div>
          </transition>
        </ValidationProvider>
        <div v-if="isAdmin" class="flex justify-end">
          <base-button
            color="primary"
            :loading="uiState === 'loading'"
            data-testid="save-button"
          >
            Save
          </base-button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import AccountAdminText from '@/components/account/AccountAdminText';
import { UserApi } from '@/api';

export default {
  components: {
    AccountAdminText,
  },
  props: {
    setWorkHours: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      languagesList: [],
      uiState: 'idle',
      error: '',
      start: '',
      end: '',
    };
  },

  computed: {
    langIds() {
      return this.languagesList.map(lang => lang.id);
    },
    hourOptions() {
      const fullHour = '00';
      let times = [];
      for (let i = 0; i < 24; ++i) {
        const leadingZero = i < 10 ? '0' : '';
        times.push(leadingZero + i + ':' + fullHour);
      }
      return times;
    },
  },

  mounted() {
    this.start = this.setWorkHours.start;
    this.end = this.setWorkHours.end;
  },

  methods: {
    async submit() {
      this.error = '';
      this.uiState = 'loading';
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        this.uiState = 'idle';
        return;
      }

      try {
        const { data } = await UserApi.updateWorkHours(
          { start: this.start, end: this.end },
          this.userId
        );
        this.$emit('updated', { start: data.start, end: data.end });
        this.uiState = 'success';

        setTimeout(() => {
          this.uiState = 'idle';
        }, 3000);
      } catch (error) {
        this.uiState = 'error';
        this.error = error.message;
      }
    },
  },
};
</script>
