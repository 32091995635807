var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("base-label", { attrs: { for: "title" } }, [_vm._v("Title")]),
      _c("v-select", {
        attrs: {
          id: "title",
          options: _vm.jobTitles,
          label: "name",
          loading: _vm.loading,
          clearable: false,
          disabled: !_vm.isAdmin,
          "data-testid": "job-title",
        },
        on: {
          input: function ($event) {
            return _vm.$emit("change", $event)
          },
        },
        model: {
          value: _vm.jobTitle,
          callback: function ($$v) {
            _vm.jobTitle = $$v
          },
          expression: "jobTitle",
        },
      }),
      !_vm.isAdmin
        ? _c(
            "div",
            {
              staticClass:
                "mt-1 flex items-center space-x-1 text-sm leading-none text-gray-600",
            },
            [
              _c("fv-icon", {
                staticClass: "text-gray-600",
                attrs: { icon: "information" },
              }),
              _c("p", [_vm._v("Job title is set by Admin")]),
            ],
            1
          )
        : _vm._e(),
      _vm.isAdmin && _vm.error
        ? _c(
            "p",
            {
              staticClass:
                "mb-2 inline-flex items-center font-semibold text-red-800",
            },
            [
              _c("fv-icon", {
                staticClass: "mr-2",
                attrs: { icon: "warning" },
              }),
              _vm._v(" " + _vm._s(_vm.error) + " "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }