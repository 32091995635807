var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "text-sm text-gray-600 flex items-center space-x-1 leading-none",
    },
    [
      _c("fv-icon", {
        staticClass: "text-gray-600",
        attrs: { icon: "information" },
      }),
      _c("p", [_vm._v(_vm._s(_vm.title) + " are set by admins")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }