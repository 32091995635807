var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "border-t border-opacity-50 flex items-baseline pt-6" },
    [
      _c(
        "div",
        { staticClass: "w-2/5" },
        [
          _c(
            "h3",
            { staticClass: "text-xl font-semibold font-display leading-none" },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          !_vm.isAdmin
            ? _c("AccountAdminText", {
                staticClass: "mt-2",
                attrs: { title: "Roles" },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.values.length
        ? _c(
            "div",
            { staticClass: "flex flex-col space-y-2" },
            _vm._l(_vm.values, function (val) {
              return _c("p", { key: val.id }, [
                _vm._v(" " + _vm._s(val.name) + " "),
              ])
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }