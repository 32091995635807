<template>
  <div>
    <title-row class="w-full">
      <div class="flex items-center justify-between">
        <h1 class="font-display text-2xl font-semibold">Update password</h1>
        <transition name="fade">
          <p
            v-if="error"
            class="mb-2 inline-flex items-center font-semibold text-red-800"
          >
            <fv-icon icon="warning" class="mr-2" />
            {{ error }}
          </p>
          <p
            v-if="uiState === 'success'"
            class="mb-2 inline-flex items-center font-semibold text-fv-green"
            data-testid="password-updated-successfully"
          >
            <fv-icon icon="check-mark-alt" class="mr-2" />
            Your password has been changed
          </p>
        </transition>
      </div>
    </title-row>

    <div class="flex justify-between space-x-6 py-4">
      <form class="flex w-1/2 flex-col space-y-4" @submit.prevent="submit">
        <div class="flex flex-col space-y-1">
          <base-label for="currentPassword"> Current password </base-label>
          <base-input
            id="currentPassword"
            v-model="currentPassword"
            :disabled="uiState === 'loading'"
            required
            type="password"
            data-testid="current-password"
          />
        </div>

        <div class="flex flex-col space-y-1 border-t border-dotted pt-6">
          <base-label for="newPassword"> New password </base-label>
          <base-input
            id="newPassword"
            v-model="newPassword"
            :disabled="uiState === 'loading'"
            required
            type="password"
            data-testid="new-password"
          />
        </div>
        <div class="flex flex-col space-y-1">
          <base-label for="newPasswordConfirm">
            Confirm new password
          </base-label>
          <base-input
            id="newPasswordConfirm"
            v-model="newPasswordConfirm"
            :disabled="uiState === 'loading'"
            required
            type="password"
            data-testid="confirm-new-password"
          />
        </div>

        <div class="flex justify-end">
          <base-button
            color="primary"
            :loading="uiState === 'loading'"
            data-testid="update-password-button"
          >
            Update password
          </base-button>
        </div>
      </form>
      <div class="w-1/3">
        <text-button color="primary" @click="showForgotPasswordForm = true">
          Forgot your password?
        </text-button>
        <base-modal
          :show="showForgotPasswordForm"
          @close="showForgotPasswordForm = false"
        >
          <ForgotPasswordForm
            :email="email"
            :editable="false"
            @cancel="showForgotPasswordForm = false"
          />
        </base-modal>
      </div>
    </div>
  </div>
</template>

<script>
import ForgotPasswordForm from '@/components/auth/ForgotPasswordForm';
import { UserApi } from '@/api';

export default {
  components: {
    ForgotPasswordForm,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uiState: 'idle',
      error: '',

      newPassword: '',
      newPasswordConfirm: '',
      currentPassword: '',

      showForgotPasswordForm: false,
    };
  },

  computed: {
    passwordMismatch() {
      return this.newPassword !== this.newPasswordConfirm;
    },
  },

  methods: {
    async submit() {
      this.uiState = 'loading';
      this.error = '';
      if (this.passwordMismatch) {
        this.uiState = 'error';
        this.error = 'Please confirm your new password.';
      }

      try {
        await UserApi.updatePassword({
          password: this.currentPassword,
          newPassword: this.newPassword,
        });

        this.uiState = 'success';
        this.clearAllFields();

        setTimeout(() => {
          this.uiState = 'idle';
        }, 3000);
      } catch (error) {
        this.uiState = 'error';
        this.error = error.message;
      }
    },

    clearAllFields() {
      this.currentPassword = '';
      this.newPassword = '';
      this.newPasswordConfirm = '';
    },
  },
};
</script>
