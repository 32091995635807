var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("title-row", { staticClass: "w-full" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-between" },
          [
            _c("h1", { staticClass: "font-display text-2xl font-semibold" }, [
              _vm._v("Animal types"),
            ]),
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [
                _vm.uiState === "loading" ? _c("base-spinner") : _vm._e(),
                _vm.error
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "mb-2 inline-flex items-center font-semibold text-red-800",
                      },
                      [
                        _c("fv-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "warning" },
                        }),
                        _vm._v(" " + _vm._s(_vm.error) + " "),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.uiState === "success"
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "mb-2 inline-flex items-center font-semibold text-fv-green",
                      },
                      [
                        _c("fv-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "check-mark-alt" },
                        }),
                        _vm._v(" Information updated "),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      !_vm.isAdmin
        ? _c("AccountAdminText", {
            staticClass: "my-2",
            attrs: { title: "Animal types" },
          })
        : _vm._e(),
      _c("div", { staticClass: "py-4" }, [
        _c(
          "ul",
          { staticClass: "grid w-3/4 grid-cols-4 gap-y-6" },
          _vm._l(_vm.animalTypes, function (animalType) {
            return _c("li", { key: animalType.id, staticClass: "col-span-1" }, [
              _c(
                "label",
                {
                  staticClass: "inline-flex items-center",
                  attrs: { for: animalType.id },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.animalTypeList,
                        expression: "animalTypeList",
                      },
                    ],
                    attrs: {
                      id: animalType.id,
                      type: "checkbox",
                      name: "animalTypeList",
                      disabled: !_vm.isAdmin,
                      "data-testid": "animal-type-checkbox",
                    },
                    domProps: {
                      value: animalType.id,
                      checked: Array.isArray(_vm.animalTypeList)
                        ? _vm._i(_vm.animalTypeList, animalType.id) > -1
                        : _vm.animalTypeList,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.animalTypeList,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = animalType.id,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.animalTypeList = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.animalTypeList = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.animalTypeList = $$c
                          }
                        },
                        _vm.update,
                      ],
                    },
                  }),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v(_vm._s(animalType.name)),
                  ]),
                ]
              ),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }