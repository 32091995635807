var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex justify-center space-x-8 py-12" }, [
    _c("div", { staticClass: "flex w-1/2 items-center" }, [
      _c(
        "div",
        {
          staticClass:
            "dropbox mx-auto mb-6 w-full px-6 py-6 text-center hover:bg-gray-50",
          class: {
            "bg-opacity-75 hover:bg-gray-50": _vm.isLoading,
            "bg-beigeLight": _vm.active,
            "bg-beigeLighter": !_vm.active,
          },
          on: {
            dragover: function ($event) {
              return _vm.fileDrag("in")
            },
            drop: _vm.fileDrag,
            dragleave: _vm.fileDrag,
          },
        },
        [
          _c(
            "form",
            {
              staticClass: "flex items-center justify-center",
              style: { height: "130px" },
              attrs: { enctype: "multipart/form-data", novalidate: "" },
            },
            [
              _c("input", {
                staticClass:
                  "absolute top-0 left-0 h-full w-full cursor-pointer opacity-0",
                attrs: { type: "file", accept: ".jpg,.gif,.png", multiple: "" },
                on: { change: _vm.loadImageFile },
              }),
              _c(
                "div",
                { staticClass: "flex flex-col items-center" },
                [
                  _vm.isLoading ? _c("base-spinner") : _vm._e(),
                  !_vm.isLoading
                    ? _c("fv-icon", {
                        attrs: { icon: "upload-40", size: "xl" },
                      })
                    : _vm._e(),
                  _c("p", { staticClass: "mb-1" }, [
                    !_vm.isLoading
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.active
                                  ? "Drop your image here"
                                  : "Drag and drop your image here "
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [_vm._v(" Uploading ... ")]),
                  ]),
                  !_vm.isLoading
                    ? _c("small", { staticClass: "mb-0" }, [
                        _vm._v(" ("),
                        _vm._m(0),
                        _vm._v(") "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "flex flex-col" },
      [
        _c("vue-croppie", {
          ref: "imageReference",
          attrs: {
            "enable-orientation": true,
            "mouse-wheel-zoom": true,
            boundary: {
              width: _vm.getBoundaryWidth(),
              height: _vm.getBoundaryHeight(),
            },
            viewport: {
              width: _vm.getViewPortWidth(),
              height: _vm.getViewPortHeight(),
              type: _vm.viewportType,
            },
            bind: { url: _vm.displayAvatarUrl },
          },
        }),
        _vm.error
          ? _c(
              "div",
              {
                staticClass:
                  "absolute top-0 left-0 flex h-full w-full items-center justify-center bg-white",
                staticStyle: { "z-index": "500" },
              },
              [
                _c("span", { staticClass: "bold text-red-600" }, [
                  _vm._v(_vm._s(_vm.error)),
                ]),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "mx-auto w-full" },
          [
            _c(
              "base-button",
              {
                staticClass: "mx-auto w-full",
                attrs: {
                  color: "primary",
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading || !_vm.hasNewImageBeenChosen,
                },
                on: { click: _vm.onCropButtonClick },
              },
              [_vm._v(" Update ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("em", [_vm._v(".jpg, .png, .gif")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }