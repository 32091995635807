<template>
  <div>
    <title-row class="w-full">
      <div class="flex justify-between items-center">
        <h1 class="font-semibold font-display text-2xl">
          Veterinary licences
        </h1>

        <transition name="fade">
          <base-spinner v-if="uiState === 'loading'" />
          <p
            v-if="error"
            class="text-red-800 mb-2 font-semibold inline-flex items-center"
          >
            <fv-icon
              icon="warning"
              class="mr-2"
            />
            {{ error }}
          </p>
          <p
            v-if="uiState === 'success'"
            class="text-fv-green mb-2 font-semibold inline-flex items-center"
          >
            <fv-icon
              icon="check-mark-alt"
              class="mr-2"
            />
            Information updated
          </p>
        </transition>
      </div>
    </title-row>
    <AccountAdminText
      v-if="!isAdmin"
      class="my-2"
      title="State licences"
    />
    <div
      class="py-4"
    >
      <base-label
        for="presciptionEnabledStates"
      >
        Add a licence for a specific state
      </base-label>
      <div class="flex space-x-12 justify-between mt-2">
        <div class="w-1/2">
          <v-select
            id="presciptionEnabledStates"
            :options="usStates"
            label="state"
            :clearable="false"
            :selectable="opt => !stateIds.includes(opt.id)"
            :disabled="!usStates.length || !isAdmin"
            :loading="!usStates.length"
            @input="updateStateLicences"
          />
        </div>
        <div class="w-1/2">
          <ul class="flex flex-col space-y-2">
            <li
              v-for="state in setStateLicences"
              :key="state.id"
              class="border p-2 rounded border-gray-200"
            >
              <div class="w-full flex justify-between">
                {{ state.state }}
              
                <button
                  :disabled="!isAdmin"
                  :class="{ 'cursor-default text-gray-400' : !isAdmin}"
                  @click="removeState(state.id)"
                >
                  <fv-icon icon="delete" />
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AccountAdminText from '@/components/account/AccountAdminText';
import { UserApi } from '@/api';

export default {
  components: {
    AccountAdminText,
  },
  props: {
    setStateLicences: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: Number,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      stateLicencesList: [],
      uiState: 'idle',
      error: '',
    };
  },

  computed: {
    ...mapState('country', ['usStates']),
    stateIds() {
      return this.stateLicencesList.map(state => state.id);
    },
  },

  mounted() {
    this.stateLicencesList = this.setStateLicences;
  },

  methods: {
    updateStateLicences(newLicence) {
      this.stateLicencesList.push(newLicence);
      this.submit();
    },

    async submit() {
      this.error = '';
      this.uiState = 'loading';
      try {
        const { data } = await UserApi.updateStateLicences(
          this.stateIds,
          this.userId
        );

        this.$emit('updated', data.us_states);
        this.uiState = 'success';

        setTimeout(() => {
          this.uiState = 'idle';
        }, 3000);
      } catch (error) {
        this.uiState = 'error';
        this.error = error.message;
      }
    },

    removeState(stateId) {
      const newArray = this.stateLicencesList.filter(
        state => state.id !== stateId
      );
      this.stateLicencesList = newArray;
      console.log(newArray);
      this.submit();
    },
  },
};
</script>
