<template>
  <div>
    <title-row class="w-full">
      <div class="flex items-center justify-between">
        <h1 class="font-display text-2xl font-semibold">Notifications</h1>

        <spinner-overlay
          :loading="!availableNotifications.length"
          color="light"
          size="xl"
        />

        <base-spinner v-if="uiState === 'loading'" />
        <p
          v-if="error"
          class="mb-2 inline-flex items-center font-semibold text-red-800"
        >
          <fv-icon icon="warning" class="mr-2" />
          {{ error }}
        </p>
        <p
          v-if="uiState === 'success'"
          class="mb-2 inline-flex items-center font-semibold text-fv-green"
          data-testid="information-saved"
        >
          <fv-icon icon="check-mark-alt" class="mr-2" />
          Information updated
        </p>
      </div>
    </title-row>
    <div
      v-for="notification in availableNotifications"
      :key="notification.id"
      class="flex items-center justify-between border-b py-4"
    >
      <div>
        <label class="mb-1 block font-semibold" :for="notification.id">
          {{ notification.label || notification.name }}
        </label>
        <span class="text-sm text-gray-600">{{
          notification.description
        }}</span>
      </div>
      <div class="form-switch max-h-full">
        <input
          :id="notification.id"
          v-model="notificationList"
          :checked="notificationList.includes(notification.id)"
          :value="notification.id"
          type="checkbox"
          class="form-switch-checkbox"
          @change="updateNotifications"
        />
        <label
          class="form-switch-label display-none"
          :for="notification.id"
          data-testid="notification-switch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { UserApi } from '@/api';

const VET_NOTIFICATION_SMS = 'appointment_vet_sms';
const CUSTOMER_NOTIFICATION = 'customer_sms';
const VET_NOTIFICATION_MAIL = 'appointment_vet_mail';

export default {
  props: {
    setNotifications: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      availableNotifications: [],
      notificationList: [],
      uiState: 'idle',
      error: '',
    };
  },
  mounted() {
    this.fetchNotificationTypes();
    this.notificationList = this.setNotifications.map(type => type.id);
  },
  methods: {
    async fetchNotificationTypes() {
      this.uiState = 'loading';
      const { data } = await UserApi.fetchNotificationTypes();
      this.availableNotifications = data.notifications
        .filter(notification => notification.name !== CUSTOMER_NOTIFICATION)
        .map(notification => {
          const value = notification;
          if (value.name === VET_NOTIFICATION_SMS) {
            value.label = 'SMS appointment reminder';
          } else if (value.name === VET_NOTIFICATION_MAIL) {
            value.label = 'Email appointment reminder';
          }
          return value;
        });
      this.uiState = 'idle';
    },
    async updateNotifications() {
      this.uiState = 'loading';
      this.error = '';
      try {
        const { data } = await UserApi.updateNotifications(
          this.notificationList,
          this.userId
        );
        this.uiState = 'success';
        this.$emit('updated', data.notifications);

        setTimeout(() => {
          this.uiState = 'idle';
        }, 3000);
      } catch (error) {
        this.uiState = 'error';
        this.error = error.message;
      }
    },
  },
};
</script>
<style scoped lang="postcss">
.form-switch-checkbox,
.form-switch-label {
  @apply bg-fv-gray;
}
</style>
