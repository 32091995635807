<!-- npm install vue-croppie --save -->
<template>
  <div class="flex justify-center space-x-8 py-12">
    <div class="flex w-1/2 items-center">
      <div
        class="dropbox mx-auto mb-6 w-full px-6 py-6 text-center hover:bg-gray-50"
        :class="{
          'bg-opacity-75 hover:bg-gray-50': isLoading,
          'bg-beigeLight': active,
          'bg-beigeLighter': !active,
        }"
        @dragover="fileDrag('in')"
        @drop="fileDrag"
        @dragleave="fileDrag"
      >
        <form
          class="flex items-center justify-center"
          enctype="multipart/form-data"
          novalidate
          :style="{ height: '130px' }"
        >
          <input
            type="file"
            accept=".jpg,.gif,.png"
            class="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
            multiple
            @change="loadImageFile"
          />
          <div class="flex flex-col items-center">
            <base-spinner v-if="isLoading" />
            <fv-icon v-if="!isLoading" icon="upload-40" size="xl" />
            <p class="mb-1">
              <span v-if="!isLoading">
                {{
                  active
                    ? 'Drop your image here'
                    : 'Drag and drop your image here '
                }}
              </span>
              <span v-else> Uploading ... </span>
            </p>
            <small v-if="!isLoading" class="mb-0">
              (<span><em>.jpg, .png, .gif</em></span
              >)
            </small>
          </div>
        </form>
      </div>
    </div>

    <div class="flex flex-col">
      <vue-croppie
        ref="imageReference"
        :enable-orientation="true"
        :mouse-wheel-zoom="true"
        :boundary="{
          width: getBoundaryWidth(),
          height: getBoundaryHeight(),
        }"
        :viewport="{
          width: getViewPortWidth(),
          height: getViewPortHeight(),
          type: viewportType,
        }"
        :bind="{ url: displayAvatarUrl }"
      />
      <div
        v-if="error"
        class="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-white"
        style="z-index: 500"
      >
        <span class="bold text-red-600">{{ error }}</span>
      </div>

      <div class="mx-auto w-full">
        <base-button
          class="mx-auto w-full"
          color="primary"
          :loading="isLoading"
          :disabled="isLoading || !hasNewImageBeenChosen"
          @click="onCropButtonClick"
        >
          Update
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueCroppie from 'vue-croppie';
import { UserApi } from '@/api';
import '@/plugins/croppie.css';

Vue.use(VueCroppie);

export default {
  components: {},
  props: {
    userId: {
      type: Number,
      required: true,
    },
    avatarUrl: {
      type: String,
      required: false,
      default: null,
    },
    viewportType: {
      type: String,
      required: false,
      default: 'circle',
      validator(value) {
        return ['circle', 'square'].includes(value);
      },
    },
    avatarType: {
      type: String,
      required: false,
      default: 'avatar',
      validator(value) {
        return ['avatar', 'call-notification-avatar'].includes(value);
      },
    },
    cropType: {
      type: String,
      required: false,
      default: 'square',
      validator(value) {
        return ['circle', 'square'].includes(value);
      },
    },
    avatarWidth: {
      type: Number,
      required: false,
      default: 250,
    },
    avatarHeight: {
      type: Number,
      required: false,
      default: 250,
    },
    avatarScaleSizePercentage: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      imageType: null,
      avatarBorderSizePercentage: 0.2,
      error: null,
      isLoading: false,
      active: false,
      displayAvatarUrl: '',
      hasNewImageBeenChosen: false,
    };
  },

  async mounted() {
    // If the avatar url is provided as a prop, then use that. Otherwise check the UserApi...
    if (this.avatarType === 'avatar') {
      this.displayAvatarUrl =
        this.avatarUrl || (await UserApi.fetchAvatar(this.userId)).data;
    } else {
      this.displayAvatarUrl =
        this.avatarUrl ||
        (await UserApi.fetchCallNotificationAvatar(this.userId)).data.avatarUrl;
    }

    this.isLoading = false;
    if (!this.displayAvatarUrl) {
      return;
    }

    this.$refs.imageReference.bind({
      url: this.displayAvatarUrl,
    });
  },

  methods: {
    getViewPortHeight() {
      if (this.avatarScaleSizePercentage) {
        return this.avatarHeight * this.avatarScaleSizePercentage;
      }
      return this.avatarHeight;
    },
    getViewPortWidth() {
      if (this.avatarScaleSizePercentage) {
        return this.avatarWidth * this.avatarScaleSizePercentage;
      }
      return this.avatarWidth;
    },
    getBoundaryHeight() {
      if (this.avatarScaleSizePercentage) {
        return (
          this.getViewPortHeight() +
          this.getViewPortHeight() * this.avatarBorderSizePercentage
        );
      }
      return (
        this.avatarHeight + this.avatarHeight * this.avatarBorderSizePercentage
      );
    },
    getBoundaryWidth() {
      if (this.avatarScaleSizePercentage) {
        return (
          this.getViewPortWidth() +
          this.getViewPortWidth() * this.avatarBorderSizePercentage
        );
      }
      return (
        this.avatarWidth + this.avatarWidth * this.avatarBorderSizePercentage
      );
    },
    fileDrag(type) {
      this.active = type === 'in' || false;
    },

    loadImageFile(fileChangedEvent) {
      const files =
        fileChangedEvent.target.files || fileChangedEvent.dataTransfer.files;
      if (!files.length) return;

      const reader = new FileReader();
      reader.onload = imageLoadedEvent => {
        this.$refs.imageReference.bind({
          url: imageLoadedEvent.target.result,
        });
      };

      const [firstFile] = files;
      this.imageType = this.mapImageType(firstFile.type);
      reader.readAsDataURL(firstFile);
      this.hasNewImageBeenChosen = true;
    },

    mapImageType(mimeType) {
      switch (mimeType) {
        case 'image/jpeg':
          return 'png';
        case 'image/png':
          return 'png';
        case 'image/gif':
          return 'gif';
        default:
          return 'png';
      }
    },

    async submit(avatarImageFile) {
      this.error = '';
      this.hasNewImageBeenChosen = false;

      try {
        if (this.avatarType === 'avatar') {
          await UserApi.updateAvatar(avatarImageFile, this.userId);
          this.displayAvatarUrl = (await UserApi.fetchAvatar(this.userId)).data;
        } else {
          await UserApi.updateCallNotificationAvatar(
            avatarImageFile,
            this.userId
          );
          this.displayAvatarUrl = (
            await UserApi.fetchCallNotificationAvatar(this.userId)
          ).data.avatarUrl;
        }
        this.$emit('avatarUpdated', this.displayAvatarUrl);
      } catch (error) {
        this.error = error.message;
      }
    },

    async onCropButtonClick() {
      this.isLoading = true;
      const imageName = `avatar-${this.userId}-${new Date().getTime()}.${
        this.imageType
      }`;

      const options = {
        type: 'blob',
        size: { width: this.avatarWidth, height: this.avatarHeight },
        format: this.imageType,
        circle: this.cropType === 'circle',
      };

      const formData = new FormData();
      formData.append('name', imageName);

      this.$refs.imageReference.result(options, outputBlob => {
        formData.append('avatar', outputBlob);
        this.submit(formData);
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
.dropbox {
  @apply relative cursor-pointer rounded-lg border border-dashed border-gray-800 text-gray-800;
}
</style>
