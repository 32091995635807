var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showOptions
        ? _c("div", {
            staticClass: "absolute inset-0 z-40",
            on: {
              click: function ($event) {
                _vm.showOptions = false
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "relative mx-auto flex flex-col" }, [
        _c(
          "div",
          { staticClass: "relative m-auto inline-block" },
          [
            _c("user-avatar", {
              staticClass: "mx-auto flex-shrink-0 rounded-full border",
              attrs: { size: 10, avatar: _vm.user.avatarUrl },
            }),
            _c("div", { staticClass: "absolute bottom-6 right-0" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "Update profile picture",
                      expression: "'Update profile picture'",
                    },
                  ],
                  staticClass:
                    "inline-flex h-9 w-9 items-center justify-center rounded-full border border-primary-lightest bg-beigeLighter shadow",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showOptions = true
                    },
                  },
                },
                [
                  _c("fv-icon", {
                    staticClass: "text-primary",
                    attrs: { icon: "add-image" },
                  }),
                ],
                1
              ),
              _vm.showOptions
                ? _c("div", { staticClass: "absolute left-6 z-50 xl:left-0" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "absolute -top-4 -right-24 flex w-52 flex-col rounded border bg-beigeLighter shadow",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "w-full py-2 px-2 text-left hover:bg-beigeLight",
                            on: {
                              click: function ($event) {
                                ;(_vm.showOptions = false),
                                  (_vm.showModal = true)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "inline-flex items-center space-x-2",
                              },
                              [
                                _c("fv-icon", { attrs: { icon: "upload-40" } }),
                                _c("span", [_vm._v("Upload profile picture")]),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm.user.avatarUrl
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "w-full py-2 px-2 text-left hover:bg-beigeLight",
                                on: {
                                  click: function ($event) {
                                    _vm.showRemoveAvatarModal = true
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "inline-flex items-center space-x-2",
                                  },
                                  [
                                    _c("fv-icon", {
                                      staticClass: "text-fv-red",
                                      attrs: { icon: "delete-alt" },
                                    }),
                                    _c("span", [
                                      _vm._v("Remove profile picture"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mx-auto mt-8 text-center" }, [
        _vm.uiState === "success"
          ? _c(
              "p",
              {
                staticClass: "inline-flex items-center space-x-2 text-fv-green",
              },
              [
                _c("fv-icon", { attrs: { icon: "check-mark-alt" } }),
                _c("span", [_vm._v("Updated profile picture")]),
              ],
              1
            )
          : _vm._e(),
        _vm.uiState === "error"
          ? _c(
              "p",
              {
                staticClass: "inline-flex items-center space-x-2 text-red-700",
              },
              [
                _c("fv-icon", { attrs: { icon: "warning" } }),
                _c("span", [_vm._v(_vm._s(_vm.error))]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "base-modal",
        {
          attrs: { show: _vm.showRemoveAvatarModal },
          on: {
            close: function ($event) {
              _vm.showRemoveAvatarModal = false
            },
          },
        },
        [
          _c("p", { staticClass: "py-4 font-semibold" }, [
            _vm._v(
              " Are you sure you want to remove the current profile picture? "
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "space-x-4",
              attrs: { slot: "buttons" },
              slot: "buttons",
            },
            [
              _c(
                "base-button",
                {
                  attrs: { color: "cancel" },
                  on: {
                    click: function ($event) {
                      _vm.showRemoveAvatarModal = false
                    },
                  },
                },
                [_vm._v(" No ")]
              ),
              _c(
                "base-button",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.uiState === "loading",
                  },
                  on: { click: _vm.removeAvatar },
                },
                [_vm._v(" Yes, remove it ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "base-modal",
        {
          attrs: { show: _vm.showModal },
          on: {
            close: function ($event) {
              _vm.showModal = false
            },
          },
        },
        [
          _c("avatar-uploader", {
            attrs: { "avatar-url": _vm.user.avatarUrl, "user-id": _vm.user.id },
            on: {
              avatarUpdated: _vm.onAvatarUpdated,
              close: function ($event) {
                _vm.showModal = false
              },
            },
          }),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  _vm.showModal = false
                },
              },
            },
            [
              _c("fv-icon", {
                staticClass:
                  "bold absolute top-2 right-2 text-black hover:text-gray-500",
                attrs: { icon: "close" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }