<template>
  <div>
    <base-label for="title">Title</base-label>
    <v-select
      id="title"
      v-model="jobTitle"
      :options="jobTitles"
      label="name"
      :loading="loading"
      :clearable="false"
      :disabled="!isAdmin"
      data-testid="job-title"
      @input="$emit('change', $event)"
    />
    <div
      v-if="!isAdmin"
      class="mt-1 flex items-center space-x-1 text-sm leading-none text-gray-600"
    >
      <fv-icon icon="information" class="text-gray-600" />
      <p>Job title is set by Admin</p>
    </div>
    <p
      v-if="isAdmin && error"
      class="mb-2 inline-flex items-center font-semibold text-red-800"
    >
      <fv-icon icon="warning" class="mr-2" />
      {{ error }}
    </p>
  </div>
</template>

<script>
import { JobTitleApi } from '@/api';

export default {
  components: {},
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
      required: true,
    },
    value: {
      type: Object,
      default: undefined,
    },
    values: {
      type: Array,
      default: undefined,
    },
  },
  emits: ['change'],

  data() {
    return {
      jobTitles: [],
      jobTitle: undefined,
      error: undefined,
      loading: false,
    };
  },

  async mounted() {
    this.jobTitle = this.value;

    this.jobTitles = this.values || [];
    if (!this.jobTitles.length && this.isAdmin) {
      this.fetchJobTitles();
    }
  },

  methods: {
    async fetchJobTitles() {
      this.loading = true;
      try {
        const { data } = await JobTitleApi.fetchJobTitles();
        this.jobTitles = data.titles;
      } catch (error) {
        this.$notify({
          group: 'error',
          title: 'Something went wrong fetching job titles',
        });
        this.error = 'Error loading job titles';
      }

      this.loading = false;
    },
  },
};
</script>
