import { render, staticRenderFns } from "./AccountNotifications.vue?vue&type=template&id=85bcdfe6&scoped=true&"
import script from "./AccountNotifications.vue?vue&type=script&lang=js&"
export * from "./AccountNotifications.vue?vue&type=script&lang=js&"
import style0 from "./AccountNotifications.vue?vue&type=style&index=0&id=85bcdfe6&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85bcdfe6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/firstvet/vetpanel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('85bcdfe6')) {
      api.createRecord('85bcdfe6', component.options)
    } else {
      api.reload('85bcdfe6', component.options)
    }
    module.hot.accept("./AccountNotifications.vue?vue&type=template&id=85bcdfe6&scoped=true&", function () {
      api.rerender('85bcdfe6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/account/AccountNotifications.vue"
export default component.exports