<template>
  <div class="flex flex-col gap-y-4">
    <title-row class="flex">
      <div class="flex flex-row items-center justify-center">
        <h1 class="font-display text-2xl font-semibold">Test queueing</h1>
        <base-spinner v-if="loading" />
        <p
          v-if="error"
          class="mx-2 inline-flex items-center font-semibold text-red-800"
        >
          <fv-icon icon="warning" class="mr-2" />
          {{ error }}
        </p>
      </div>
    </title-row>

    <div class="flex flex-col gap-y-1">
      <base-label>Number of users to add to queue</base-label>
      <div class="flex flex-row flex-wrap gap-x-2 gap-y-2">
        <base-input
          id="usersToAdd"
          v-model="usersToAdd"
          class="flex min-w-60 flex-1"
          type="number"
          data-testid="users-to-add"
          min="0"
        />
        <base-button
          :loading="loading"
          class="flex min-w-60 flex-1"
          @click="addUsersToQueue"
          >Add</base-button
        >
      </div>
    </div>

    <div class="flex flex-col gap-y-1">
      <base-label>Previous runs</base-label>
      <li v-for="(run, index) in runs" :key="index" class="list-none">
        <p>{{ run }}</p>
      </li>
      <label v-if="hasNoPrevRuns">No previous runs</label>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { format } from 'date-fns';

const dtFormat = 'yyyy-MM-dd HH:mm';
const PREV_RUNS_KEY = 'AccountTestQueue.previousRuns';

export default {
  data() {
    return {
      loading: false,
      error: '',
      runs: this.getPrevRuns(),
      usersToAdd: '3',
    };
  },
  computed: {
    hasNoPrevRuns() {
      return this.runs.length === 0;
    },
  },
  methods: {
    ...mapActions('waitingRoom', ['addRandomUsersToTestQueue']),
    getPrevRuns: () => {
      const old = localStorage.getItem(PREV_RUNS_KEY);
      try {
        const value = JSON.parse(old);
        return Array.isArray(value) ? value : [];
      } catch {
        return [];
      }
    },
    updateRuns(result) {
      const resultData = result.data?.data;
      if (resultData?.usersAddedToQueue?.length > 0) {
        const dateString = format(Date.now(), dtFormat);
        this.runs.unshift(
          `${dateString} - added ${resultData.usersAddedToQueue.length} user to the queue`
        );
        this.runs = this.runs.slice(0, 10);
        localStorage.setItem(PREV_RUNS_KEY, JSON.stringify(this.runs));
      } else {
        this.error = 'Found no suitable users';
        this.runs.unshift('No suitable users found');
      }
    },
    async addUsersToQueue() {
      this.loading = true;
      this.error = '';
      const usersToAdd = parseInt(this.usersToAdd);

      await this.addRandomUsersToTestQueue(isNaN(usersToAdd) ? 3 : usersToAdd)
        .then(this.updateRuns)
        .catch(error => {
          this.error = error.message;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
