<template>
  <div class="border-t border-opacity-50 flex items-baseline pt-6">
    <div class="w-2/5">
      <h3 class="text-xl font-semibold font-display leading-none">
        {{ title }}
      </h3>
      <AccountAdminText
        v-if="!isAdmin"
        class="mt-2"
        title="Roles"
      />
    </div>
    <div
      v-if="values.length"
      class="flex flex-col space-y-2"
    >
      <p
        v-for="val in values"
        :key="val.id"
      >
        {{ val.name }}
      </p>
    </div>
  </div>
</template>

<script>
import AccountAdminText from '@/components/account/AccountAdminText';

export default {
  components: {
    AccountAdminText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
