<template>
  <div>
    <div
      v-if="showOptions"
      class="absolute inset-0 z-40"
      @click="showOptions = false"
    />
    <div class="relative mx-auto flex flex-col">
      <div class="relative m-auto inline-block">
        <user-avatar
          :size="10"
          class="mx-auto flex-shrink-0 rounded-full border"
          :avatar="user.avatarUrl"
        />

        <div class="absolute bottom-6 right-0">
          <button
            v-tooltip="'Update profile picture'"
            class="inline-flex h-9 w-9 items-center justify-center rounded-full border border-primary-lightest bg-beigeLighter shadow"
            color="primary"
            @click="showOptions = true"
          >
            <fv-icon class="text-primary" icon="add-image" />
          </button>
          <div v-if="showOptions" class="absolute left-6 z-50 xl:left-0">
            <div
              class="absolute -top-4 -right-24 flex w-52 flex-col rounded border bg-beigeLighter shadow"
            >
              <button
                class="w-full py-2 px-2 text-left hover:bg-beigeLight"
                @click="(showOptions = false), (showModal = true)"
              >
                <div class="inline-flex items-center space-x-2">
                  <fv-icon icon="upload-40" />
                  <span>Upload profile picture</span>
                </div>
              </button>
              <button
                v-if="user.avatarUrl"
                class="w-full py-2 px-2 text-left hover:bg-beigeLight"
                @click="showRemoveAvatarModal = true"
              >
                <div class="inline-flex items-center space-x-2">
                  <fv-icon icon="delete-alt" class="text-fv-red" />
                  <span>Remove profile picture</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-auto mt-8 text-center">
      <p
        v-if="uiState === 'success'"
        class="inline-flex items-center space-x-2 text-fv-green"
      >
        <fv-icon icon="check-mark-alt" />
        <span>Updated profile picture</span>
      </p>
      <p
        v-if="uiState === 'error'"
        class="inline-flex items-center space-x-2 text-red-700"
      >
        <fv-icon icon="warning" />
        <span>{{ error }}</span>
      </p>
    </div>
    <base-modal
      :show="showRemoveAvatarModal"
      @close="showRemoveAvatarModal = false"
    >
      <p class="py-4 font-semibold">
        Are you sure you want to remove the current profile picture?
      </p>
      <div slot="buttons" class="space-x-4">
        <base-button color="cancel" @click="showRemoveAvatarModal = false">
          No
        </base-button>
        <base-button
          color="primary"
          :loading="uiState === 'loading'"
          @click="removeAvatar"
        >
          Yes, remove it
        </base-button>
      </div>
    </base-modal>

    <base-modal :show="showModal" @close="showModal = false">
      <avatar-uploader
        :avatar-url="user.avatarUrl"
        :user-id="user.id"
        @avatarUpdated="onAvatarUpdated"
        @close="showModal = false"
      />
      <button @click="showModal = false">
        <fv-icon
          icon="close"
          class="bold absolute top-2 right-2 text-black hover:text-gray-500"
        />
      </button>
    </base-modal>
  </div>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar';
import AvatarUploader from '@/components/user/AvatarUploader';
import { UserApi } from '@/api';
import { mapGetters } from 'vuex';

export default {
  components: {
    UserAvatar,
    AvatarUploader,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uiState: 'idle',
      showModal: false,
      showOptions: false,
      showRemoveAvatarModal: false,
    };
  },
  computed: {
    ...mapGetters('user', { loggedInUserid: 'getUserId' }),
  },
  methods: {
    async removeAvatar() {
      try {
        this.uiState = 'loading';
        await UserApi.removeAvatar(this.user.id);
        this.uiState = 'success';
        this.showRemoveAvatarModal = false;
        this.showOptions = false;
        this.$emit('removed');
      } catch (error) {
        this.uiState = 'error';
        this.error = error.message;
      }
      this.closeAll();
    },

    onAvatarUpdated(newAvatarUrl) {
      this.closeAll();
      this.uiState = 'success';
      this.user.avatarUrl = newAvatarUrl;
      if (this.user.id === this.loggedInUserid) {
        this.$store.commit('user/setUser', this.user);
      }
    },

    closeAll() {
      this.showModal = false;
      this.showOptions = false;
      this.showRemoveAvatarModal = false;
    },
  },
};
</script>
