var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mr-6 flex flex-col pt-8 bg-gray-50 pr-2 border-r" },
    _vm._l(_vm.buttons, function (button) {
      return _c("AccountNavButton", {
        key: button.name,
        attrs: { button: button, selected: button.name === _vm.selected },
        on: {
          selected: function ($event) {
            return _vm.$emit("selected", button.name)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }