var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("title-row", { staticClass: "w-full" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-between" },
          [
            _c("h1", { staticClass: "font-display text-2xl font-semibold" }, [
              _vm._v("Update password"),
            ]),
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.error
                ? _c(
                    "p",
                    {
                      staticClass:
                        "mb-2 inline-flex items-center font-semibold text-red-800",
                    },
                    [
                      _c("fv-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "warning" },
                      }),
                      _vm._v(" " + _vm._s(_vm.error) + " "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.uiState === "success"
                ? _c(
                    "p",
                    {
                      staticClass:
                        "mb-2 inline-flex items-center font-semibold text-fv-green",
                      attrs: { "data-testid": "password-updated-successfully" },
                    },
                    [
                      _c("fv-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "check-mark-alt" },
                      }),
                      _vm._v(" Your password has been changed "),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flex justify-between space-x-6 py-4" }, [
        _c(
          "form",
          {
            staticClass: "flex w-1/2 flex-col space-y-4",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "flex flex-col space-y-1" },
              [
                _c("base-label", { attrs: { for: "currentPassword" } }, [
                  _vm._v(" Current password "),
                ]),
                _c("base-input", {
                  attrs: {
                    id: "currentPassword",
                    disabled: _vm.uiState === "loading",
                    required: "",
                    type: "password",
                    "data-testid": "current-password",
                  },
                  model: {
                    value: _vm.currentPassword,
                    callback: function ($$v) {
                      _vm.currentPassword = $$v
                    },
                    expression: "currentPassword",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col space-y-1 border-t border-dotted pt-6",
              },
              [
                _c("base-label", { attrs: { for: "newPassword" } }, [
                  _vm._v(" New password "),
                ]),
                _c("base-input", {
                  attrs: {
                    id: "newPassword",
                    disabled: _vm.uiState === "loading",
                    required: "",
                    type: "password",
                    "data-testid": "new-password",
                  },
                  model: {
                    value: _vm.newPassword,
                    callback: function ($$v) {
                      _vm.newPassword = $$v
                    },
                    expression: "newPassword",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex flex-col space-y-1" },
              [
                _c("base-label", { attrs: { for: "newPasswordConfirm" } }, [
                  _vm._v(" Confirm new password "),
                ]),
                _c("base-input", {
                  attrs: {
                    id: "newPasswordConfirm",
                    disabled: _vm.uiState === "loading",
                    required: "",
                    type: "password",
                    "data-testid": "confirm-new-password",
                  },
                  model: {
                    value: _vm.newPasswordConfirm,
                    callback: function ($$v) {
                      _vm.newPasswordConfirm = $$v
                    },
                    expression: "newPasswordConfirm",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex justify-end" },
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      color: "primary",
                      loading: _vm.uiState === "loading",
                      "data-testid": "update-password-button",
                    },
                  },
                  [_vm._v(" Update password ")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "w-1/3" },
          [
            _c(
              "text-button",
              {
                attrs: { color: "primary" },
                on: {
                  click: function ($event) {
                    _vm.showForgotPasswordForm = true
                  },
                },
              },
              [_vm._v(" Forgot your password? ")]
            ),
            _c(
              "base-modal",
              {
                attrs: { show: _vm.showForgotPasswordForm },
                on: {
                  close: function ($event) {
                    _vm.showForgotPasswordForm = false
                  },
                },
              },
              [
                _c("ForgotPasswordForm", {
                  attrs: { email: _vm.email, editable: false },
                  on: {
                    cancel: function ($event) {
                      _vm.showForgotPasswordForm = false
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }