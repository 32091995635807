<template>
  <div>
    <title-row class="w-full">
      <div class="flex items-center justify-between">
        <h1 class="font-display text-2xl font-semibold">Languages</h1>

        <transition name="fade">
          <base-spinner v-if="uiState === 'loading'" />
          <p
            v-if="error"
            class="mb-2 inline-flex items-center font-semibold text-red-800"
          >
            <fv-icon icon="warning" class="mr-2" />
            {{ error }}
          </p>
          <p
            v-if="uiState === 'success'"
            class="mb-2 inline-flex items-center font-semibold text-fv-green"
          >
            <fv-icon icon="check-mark-alt" class="mr-2" />
            Information updated
          </p>
        </transition>
      </div>
    </title-row>
    <AccountAdminText v-if="!isAdmin" class="my-2" title="Languages" />
    <div class="py-4">
      <base-label for="selectedLanguages"> Add a language </base-label>
      <div class="mt-2 flex justify-between space-x-12">
        <div class="w-1/2">
          <v-select
            id="selectedLanguages"
            :options="availableLanguages"
            label="name"
            :clearable="false"
            :selectable="opt => !langIds.includes(opt.id)"
            :disabled="uiState === 'loadingLangages' || !isAdmin"
            data-testid="add-language"
            @input="updateLanguages"
          />
        </div>
        <div class="w-1/2">
          <ul class="flex flex-col space-y-2">
            <li
              v-for="lang in setLanguages"
              :key="lang.id"
              class="rounded border border-gray-200 p-2"
            >
              <div class="flex w-full justify-between">
                <div class="inline-flex items-center space-x-2">
                  <span
                    ><img class="h-6 w-6 rounded-full" :src="lang.flag_image"
                  /></span>
                  <span class="">{{ lang.name }}</span>
                </div>
                <button
                  :disabled="!isAdmin"
                  :class="{ 'cursor-default text-gray-400': !isAdmin }"
                  @click="removeLanguage(lang.id)"
                >
                  <fv-icon icon="delete" />
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountAdminText from '@/components/account/AccountAdminText';
import { UserApi, CountryApi } from '@/api';

export default {
  components: {
    AccountAdminText,
  },
  props: {
    setLanguages: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: Number,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      availableLanguages: [],
      languagesList: [],
      uiState: 'idle',
      error: '',
    };
  },

  computed: {
    langIds() {
      return this.languagesList.map(lang => lang.id);
    },
  },

  mounted() {
    this.fetchLanguages();
    this.languagesList = this.setLanguages;
  },

  methods: {
    async fetchLanguages() {
      this.uiState = 'loadingLangages';
      const { data } = await CountryApi.fetchLanguages();
      this.availableLanguages = data.languages;
      this.uiState = 'idle';
    },

    updateLanguages(newLanguage) {
      this.languagesList.push(newLanguage);
      this.submit();
    },

    async submit() {
      this.error = '';
      this.uiState = 'loading';
      try {
        const { data } = await UserApi.updateLanguages(
          this.langIds,
          this.userId
        );
        this.$emit('updated', data.languages);
        this.uiState = 'success';

        setTimeout(() => {
          this.uiState = 'idle';
        }, 3000);
      } catch (error) {
        this.uiState = 'error';
        this.error = error.message;
      }
    },

    removeLanguage(languageId) {
      const newArray = this.languagesList.filter(
        lang => lang.id !== languageId
      );
      this.languagesList = newArray;
      this.submit();
    },
  },
};
</script>
